@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

:root {
  --bg-color: rgb(32, 32, 32);
  --text-color: #fff;
  --header-color: rgb(24, 24, 24);
  --dev-color: rgb(28, 212, 236);
  --text-form: #fff;
}

[data-theme="light"] {
  --bg-color: rgb(240, 245, 242);
  --text-color: rgb(24, 24, 24);
  --header-color: rgb(216, 221, 216);
  --dev-color: rgb(28, 212, 236);
  --text-form: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: scroll;
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
