.projectCard {
  width: 30rem;
  height: 30rem;
  margin: 1rem 2.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* box-shadow: 1px 4px 1px 5px rgba(0,0,0,1); */
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.projectCard:hover {
  opacity: 1;
  transform: scale(1.02);
}

.whiteScreenCard {
  opacity: 1;
}

.detail_card {
  width: 10rem;
  height: 10rem;
  background: yellow;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.show_detail_card {
  opacity: 1;
}

@media (max-width: 1099px) {
  .projectCard {
    margin: 1rem auto;
  }
}
