#homepage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_data {
  height: 80vh;
  width: 90vw;
  display: flex;
  margin-top: 5vh;
}

.homepage_dataAboutMe {
  height: 80vh;
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personal_infos {
  height: 50rem;
  width: 100%;
  padding: 10rem 0 0 12rem;
}

.personal_infoSayHi,
.personal_infoName,
.personal_infoJob {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.personal_infoSkill h3 {
  font-size: 2rem;
  color: var(--dev-color);
}

.homepage_dataTechs {
  height: 80vh;
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tagcloud {
  z-index: 1;
}

#tagcloud ul > li > p {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 900;
}

/* Squeez */
@keyframes rubber {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.2, 0.7, 1);
  }
  40% {
    transform: scale3d(0.7, 1.2, 1);
  }
  50% {
    transform: scale3d(1.1, 0.8, 1);
  }
  65% {
    transform: scale3d(0.9, 1, 1);
  }
  75% {
    transform: scale3d(1, 0.9, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.black_text{
  color: black ;
}

.white_text{
  color: white ;
}

.squeez {
  transition: all 0.3s ease-out;
  opacity: 0;
  /* color: white; */
  display: inline-block;
  font-weight: 900;
}

.squeez_big {
  font-size: 8rem;
}

.squeez_small {
  font-size: 7rem;
}

.squeez:hover {
  color: var(--dev-color);
}

.squeez:hover.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.squeez:hover.rubber {
  animation-name: rubber;
}

@media (max-width: 800px) {
  .homepage_data {
    flex-direction: column;
  }

  .personal_infos {
    padding: 15rem 0 0 5rem;
  }

  .squeez_big {
    font-size: 6rem;
  }

  .squeez_small {
    font-size: 5rem;
  }
}

@media (max-width: 450px) {
  .homepage_data {
    width: 100%;
    min-height: 100%;
    margin-top: 0;
  }

  .homepage_dataAboutMe {
    width: 100%;
    height: 60%;
  }

  .personal_infos {
    padding: 15rem 0 0 5rem;
  }

  /* .personal_infoSayHi h1,
  .personal_infoName h1,
  .personal_infoJob h1 {
    font-size: 5rem;
    color: var(--text-color);
  } */

  .squeez_big {
    font-size: 5rem;
  }

  .squeez_small {
    font-size: 4rem;
  }

  .homepage_dataTechs {
    width: 100%;
    padding-bottom: 1rem;
  }

  #tagcloud {
    width: 100%;
  }
}
