.projects {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects_area {
  width: 80vw;
  /* max-height: 80vh; */
  margin-top: 5rem;
  /* overflow: scroll; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.projects_area::-webkit-scrollbar {
  display: none;
}

@media (max-width: 800px) {
  .projects_area {
    padding: 5rem 0;
  }
}
