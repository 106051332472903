.popup {
  width: 0%;
  height: 0%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
}

.popup_show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.photo_area {
  width: 50%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo_area > img{
  max-width: 100%;
}

.left_button {
  padding-left: 3rem;
  cursor: pointer;
}

.right_button {
  padding-right: 3rem;
  cursor: pointer;
}

.left_button > i,
.right_button > i {
  font-size: 2.5rem;
  color: var(--text-color);
}
