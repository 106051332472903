.header {
  background: var(--header-color);
  position: fixed;
  opacity: 1;
  width: 100vw;
  top: 0;
  z-index: 100;
}

.header_container {
  max-width: 80vw;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.6rem;
  flex: 0.2;
}

.logo_text {
  text-decoration: none;
  color: var(--text-color);
}

.menu_items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0.65;
  padding: 0 2rem;
}

.menu_item {
  list-style: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  margin-right: 2rem;
  font-size: 1.6rem;
  text-decoration: none;
  font-weight: 600;
  color: var(--text-color);
}

.menu_item:hover {
  border-bottom: 1px solid var(--text-color);
  cursor: pointer;
}

.socials {
  flex: 0.15;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social > a > i {
  font-size: 2.2rem;
  color: var(--text-color);
}

.change_theme {
  width: 4rem;
  height: 2.2rem;
  border-radius: 1.2rem;
  background: var(--text-color);
  position: relative;
  cursor: pointer;
}

.theme_ball {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: var(--bg-color);
  position: absolute;
}

.lighty {
  animation: slideBallLight 0.5s normal;
  right: 0;
}

.darky {
  animation: slideBallDark 0.5s normal;
  right: 45%;
}

.lighty_stop {
  right: 0;
}

.darky_stop {
  right: 45%;
}

.hamburger_menu,
.fa-times,
.fa-bars {
  display: none;
}

@keyframes slideBallLight {
  from {
    right: 45%;
  }
  to {
    right: 0;
  }
}

@keyframes slideBallDark {
  from {
    right: 0;
  }
  to {
    right: 45%;
  }
}

/* Responsive */
@media (max-width: 1050px) {
  .header_container {
    max-width: 90%;
  }
}

@media (max-width: 900px) {
  .header_container {
    max-width: 98%;
  }
}

@media (max-width: 800px) {
  .menu_items,
  .socials {
    display: none;
  }

  .fa-times,
  .fa-bars {
    display: block;
  }

  .hamburger_items {
    position: relative;
  }

  .hamburger_items > i {
    font-size: 2.2rem;
    color: var(--text-color);
  }

  .hamburger_menu {
    display: none;
    width: 100vw;
    background: var(--header-color);
  }

  .showMenu {
    display: block;
    border: 1px solid var(--header-color);
  }

  .hamburger_menuItem {
    font-size: 1.6rem;
    font-weight: 600;
    list-style: none;
    padding: 1rem;
    border-bottom: 1px solid var(--text-color);
    font-weight: 600;
    color: var(--text-color);
    font-size: 1.6rem;
    text-decoration: none;
  }

  .hamburger_socials {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .hamburger_social > a > i {
    font-size: 2.2rem;
    color: var(--text-color);
    padding: 1rem 0;
  }
}

@media (max-width: 450px) {
  .header_container {
    max-width: 100%;
  }
}
