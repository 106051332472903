.contact {
  background: var(--bg-color);
  height: 100vh;
}

.contact_area {
  width: 80vw;
  height: 100vh;
  display: flex;
  margin: 0 auto;
}

.contact_form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact_form > h1 {
  font-size: 5rem;
  color: var(--dev-color);
}

.contact_form > form {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.name_email {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.name_email > .name,
.name_email > .email {
  width: 48%;
  height: 5rem;
}

.subject {
  width: 100%;
  height: 5rem;
}

.name,
.email,
.subject,
.text {
  background: rgba(42, 42, 42, 1);
  border: none;
  outline: none;
  margin: 1rem 0;
  font-size: 1.7rem;
  padding: 1rem;
  color: var(--text-form);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.text {
  width: 100%;
  height: 20rem;
  resize: none;
  font-size: 1.7rem;
}

.form_button {
  padding: 2rem 4rem;
  align-self: flex-end;
  background: var(--bg-color);
  border: 2px solid var(--dev-color);
  color: var(--dev-color);
  font-size: 1.8rem;
  cursor: pointer;
  outline: none;
}

.form_button:hover {
  color: var(--text-color);
}

@media (max-width: 1050px) {
  .contact_form > form {
    width: 70%;
  }
}

@media (max-width: 950px) {
  .contact_form > form {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .contact_area {
    width: 100%;
  }

  .contact_form > form {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .name_email {
    flex-direction: column;
  }
  .name_email > .name,
  .name_email > .email {
    width: 100%;
  }

  .form_button {
    padding: 1rem 2rem;
  }
}

/* .map{
  max-width: 100%;
  flex: 0.5;
  position: relative;
  padding-top: calc(20% / 2);
  padding-left: 1rem;
} */
